import { Link } from 'react-router-dom';

export default function About() {
  return (
    <>
      <div className="mx-4 md:mx-20 lg:mx-40 my-6 md:my-12 lg:my-20">
        <h1>We love to <span className="text-red-700">code</span> and it shows.</h1>
        <h3 className="my-4">We've been developing for the web since the web was a thing.</h3>

        <p className="my-4 mt-6 md:mt-8 lg:mt-10">
          Whether developing with a business partner, independently, or building a
          cool new MVP, we are passionate about developing software that performs, is modern,
          and looks and feels right.
        </p>

      </div>

      <div className="mx-4 md:mx-20 lg:mx-40 my-6 md:my-12 lg:my-20">
        <h2 className="border-b">Years of experience</h2>
        <p className="mt-2 md:mt-8 lg:mt-10">
          While times have changed, we have changed with the times. We have
          worked with all the hot technologies as they have come and gone.
          Our passion for JavaScript remains.
        </p>
      </div>

      <div className="mx-4 md:mx-20 lg:mx-40 my-6 md:my-12 lg:my-20 bg-gray-100 p-6">
        <h2 className="border-b">Our Core Values</h2>
        <div className="grid md:grid-cols-2 gap-4">
          <p className="mt-2 md:mt-8 lg:mt-10">
            <h4>People</h4> Our people, our partners, our customers are
            all important. The value of the individual and relationships
            outweigh anything else.
          </p>
          <p className="mt-2 md:mt-8 lg:mt-10">
            <h4>Solutions</h4> We are in business to deliver solutions, not
            writing volumes of documentation that no one will ever read. We
            provide documentation but just the right amount. 
          </p>

          <p className="mt-2 md:mt-8 lg:mt-10">
            <h4>Collaboration</h4>
             We work with you to understand your needs, come to a shared 
            understanding, and communicate regularly. Through constant
             iteration, we fail fast and make adjustments.
          </p>
          <p className="mt-2 md:mt-8 lg:mt-10">
            <h4>Adaptability</h4>
            The only thing constant is change! Whether it is a business requirement,
            a tool selection, a platform, or a design, it is bound to change. By
            remaining agile (an overused buzzword), we respond to change quickly.
          </p>

        </div>
      </div>
      
      
      <div className="bg-gray-200 grid md:grid-cols-6  md:px-10 gap-4 py-2 px-2">
        <div className="col-span-4">
          <h3>Have a project in mind?</h3>
          <h1>Let's Talk</h1>
        </div>
        <div className="md:col-start-5 md:col-span-2 md:my-8">
          <Link to="/contact">
            <button className="p-2">Get started today</button>
          </Link>
        </div>
      </div>
    </>
  );
}
