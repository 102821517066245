
export default function Hero() {
  return (
    <div className="text-center tracking-widest text-6xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl 2xl:mt-10 animate__animated animate__rubberBand animate__delay-4s">
      <span id="X" className="animate__animated animate__fadeIn">X</span>
      <span id="O1" className="animate__animated animate__fadeIn animate__delay-1s">O</span>
      <span id="S" className="animate__animated animate__fadeIn animate__delay-2s">S</span>
      <span id="O2" className="animate__animated animate__fadeIn animate__delay-3s">O</span>
    </div>
  );
}
