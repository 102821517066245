import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Header from './components/header.js';
import Footer from './components/footer.js';
import Home from './pages/home.js';
import About from './pages/about.js';
import Contact from './pages/contact.js';

export function App() {
  return (
    <div className="flex flex-col h-screen justify-between">
      <Router>
        <div>
          <nav className="text-gray-400 flex items-end text-xs border-b py-1 animate__animated animate__fadeInDown animate-delay-5s">
            <div className="">
            </div>
            <div className="mx-3">
              <Link to="/">
                <div className="logo"></div>
              </Link>
            </div>
            <div className="mx-3"><Link to="/about">About</Link></div>
            <div className="mx-3"><Link to="/contact">Contact</Link></div>
          </nav>

          <Switch>
            <Route path="/about"><About /></Route>
            <Route path="/contact"><Contact /></Route>
            <Route path="/"><Home /></Route>
          </Switch>
        </div>
      </Router>
      
      <Footer/>
    </div>
  );
}

