import { useState } from 'react';

export default function Navbar({clickHandler}) {
  const [active, setActive] = useState('');

  function selectionMade(item) {
    console.log('selection made: ', item);
    setActive(item);
    clickHandler(item);
  }
  
  return (
    <div className="bg-gray-100 text-gray-400 flex justify-end text-xs">
      <div className="mx-3">
        <button onClick={() => {selectionMade('about')}}>About</button>
      </div>
      
      <div className="mx-3">
        <button onClick={() => {clickHandler('contact')}}>Contact</button>
      </div>
    </div>
  );
};
