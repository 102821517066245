import { useState } from 'react';
import { sendEmail } from '../lib/mailutils.js';

export default function Contact() {
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ statusMessage, setStatusMessage ] = useState('');

    const handleSubmit = (evt) => {
        evt.preventDefault();
        console.log('Submitting!');
        sendEmail({ firstName, lastName, email, company, message });

        setFirstName('');
        setLastName('');
        setEmail('');
        setCompany('');
        setMessage('');

        // Display message
        setStatusMessage('Your message has been sent. We will be in touch soon.');
        window.scrollTo(0, 0);
    };
    
  return (
    <div className="mx-4 md:mx-20 lg:mx-40 my-6 md:my-12 lg:my-20">
      <h1>Let's do <span className="text-red-700">something</span> spectacular</h1>

      {statusMessage.length > 0 &&
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded mt-6" role="alert">
        <p className="font-bold">Thank you!</p>
        <p>{statusMessage}</p>
      </div>
      }

      <p className="my-4 mt-6 md:mt-8 lg:mt-10">
        Please complete the form below. You message will be
        confidentially sent to the xoso team. We'll get back
        with you as soon as possible. You can also drop us an
        email at <a href="mailto:hello@xoso.io">hello@xoso.io</a>.
      </p>

      <div className="mt-8 max-w-md">
        <div className="grid grid-cols-1 gap-6">
          <form onSubmit={handleSubmit}>
          <label className="block">
            <span className="text-gray-700">First Name</span>
            <input type="text"
                   className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring-opacity-50"
                   value={firstName}
                   onChange={e => setFirstName(e.target.value)}
            />
          </label>
      
          <label className="block">
            <span className="text-gray-700">Last Name</span>
            <input type="text"
                   className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring-opacity-50"
                   value={lastName}
                   onChange={e => setLastName(e.target.value)}
            />
          </label>

          <label className="block">
            <span className="text-gray-700">What is your email address?</span>
            <input type="email"
                   className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring-opacity-50"
                   value={email}
                   onChange={e => setEmail(e.target.value)}
            />
          </label>

          <label className="block">
            <span className="text-gray-700">What is your company name?</span>
            <input type="text"
                   className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring-opacity-50"
                   value={company}
                   onChange={e => setCompany(e.target.value)}
            />
          </label>

          <label className="block">
            <span className="text-gray-700">How can xoso help you?</span>
            <textarea className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring-opacity-50"
                      rows="3"
                      value={message}
                      onChange={e => setMessage(e.target.value)}
            />
          </label>

          <div className="my-4">
            <button>Submit</button>
          </div>

        </form>
        </div>
      </div>
    </div>
  );
}
