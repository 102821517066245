import Hero from '../components/hero.js';

export default function Home() {
  return (
    <div id="heroContainer" className="mx-auto my-20">

      <Hero />
      
      <div className="text-center mt-10">
        <a className="mailto text-blue-600 animate__animated animate__fadeInUp animate-delay-4.5s"
           href="mailto:hello@xoso.io">
          hello@xoso.io
        </a>
      </div>
    </div>
  );
}
