import Navbar from './navbar.js';

export default function Header() {
  function navHandler(msg) {
    console.log('event', msg);
  }

  return (
    <div>
      <Navbar clickHandler={navHandler}/>
    </div>
  );
}
