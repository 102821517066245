// const apiBase = 'http://localhost:3000';
const apiBase = 'https://api.xoso.io';

const sendEmail = async  (email) => {
  
  console.log(email);

  const url = `${apiBase}/mail`;

  const data = Object.assign({ uid: 'dlkfjwepoifje' }, email);

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(email)
  });
  return response.json();
};

export {
  sendEmail
};

